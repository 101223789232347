<!--平台加载设置-->
<template>
  <div class="box">
    <global-info-bar title="平台加价设置" content="设置平台参与到订单的加价比例以及功能开关" />
    <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
          <div v-bind:class="clickShow === '01' ? 'active' : 'none'" @click="BtnClick('01')">
            平台自动加价配置
          </div>
          <div v-bind:class="clickShow === '02' ? 'active' : 'none'" @click="BtnClick('02')">
            平台额外补贴加价
          </div>
          <div v-bind:class="clickShow === '03' ? 'active' : 'none'" @click="BtnClick('03')">
            虚拟报价设置
          </div>
          <div v-bind:class="clickShow === '04' ? 'active' : 'none'" @click="BtnClick('04')">
            议价设置
          </div>
        </div>
      </div>
    </div>
    <!--内容-->
    <div class="inner" v-if="clickShow === '01'">
      <!-- 加价 -->
      <div class="opening_box">
        <div class="tip-header">
          <div>
            <img src="../../assets/images/icon_ygtjslb_4.png" alt="">
          </div>
          <p>是否启用平台加价</p>
        </div>
        <div class="opening_switch">
          <el-switch v-model="openingValue" active-color="#0981ff" inactive-color="#ccc">
          </el-switch>
        </div>
      </div>
      <!-- 平台 -->
      <div class="terrace_box">
        <div class="tip-header">
          <div>
            <img src="../../assets/images/icon_ygtjslb_4.png" alt="">
          </div>
          <p>选择平台加价方式 <i @click="ruleShow = true">查看规则</i></p>
        </div>
        <!-- 选择 -->
        <div class="terrace_check">
          <el-radio-group v-model="configData.addType">
            <el-radio label="00">手动加价</el-radio>
            <el-radio label="01">自动触发加价</el-radio>
          </el-radio-group>
          <p class="terrace_header" v-show="configData.addType === '01'">自动加价时间段<i @click="timeSetClick">设置</i>
          </p>
          <div class="terrace_tb_title" v-show="configData.addType === '01'">
            <p class="terrace_header">设置自动门店加价</p>
            <el-button type="primary" size="small" @click="addStore">+添加门店</el-button>
          </div>
          <div class="terrace_table" v-show="configData.addType === '01'">
            <el-table :data="configData.storeList" style="width: 100%;" :max-height="400"
              :header-cell-style="{ background: '#E4ECFD', padding: 0 }" border>
              <el-table-column v-for="item of storeColumns" :prop="item.prop" :key="item.prop" align="center"
                :label="item.label" :width="item.width">
                <template v-slot="{ row }">
                  <p v-if="item.prop === 'action'" style="color: red;cursor: pointer" @click="del(row)">删除</p>
                  <div v-else-if="item.prop === 'tagList'" style="overflow:hidden;">
                    <div v-show="Array.isArray(row[item.prop]) && row[item.prop].length">
                      <p class="terrace_tag" :title="item.tagName" v-for="(item, index) in row[item.prop]" :key="index">
                        {{ item.tagName }}</p>
                    </div>
                    <div v-show="!(Array.isArray(row[item.prop]) && row[item.prop].length)">--</div>
                  </div>
                  <p v-else-if="item.prop === 'quoteMerchants'" style="color: #0981FF;">{{ row[item.prop] }}</p>
                  <p v-else>{{ row[item.prop] }}</p>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!--  比例 -->
      <div class="scale_box">
        <div class="tip-header">
          <div>
            <img src="../../assets/images/icon_wwfwl5.png" alt="">
          </div>
          <p>设置平台加价比例</p>
        </div>
        <div class="scale_table">
          <el-table :data="configData.regionList" style="width: 100%" border
            :header-cell-style="{ background: '#E4ECFD', padding: 0 }">
            <el-table-column v-for="item of scaleColumns" :prop="item.prop" :key="item.prop" align="center"
              :label="item.label" :width="item.width">
              <template v-slot="{ row }">
                <p v-if="item.prop === 'action'" style="color: #0981FF;cursor: pointer" @click="edit(row)">编辑</p>
                <p v-else>{{ row[item.prop] }}</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 保存 -->
    <div style="padding-left: 20px;padding-top: 30px;" v-if="clickShow === '01'">
      <el-button @click="getData()">取消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
    <!--内容-->
    <div class="inner" v-if="clickShow === '02'">
      <!-- 加价 -->
      <div class="opening_box">
        <div class="tip-header">
          <div>
            <img src="../../assets/images/icon_ygtjslb_4.png" alt="">
          </div>
          <p>是否启用平台补贴加价</p>
        </div>
        <div class="opening_switch">
          <el-switch v-model="isopenSubsidy" @change="subsidyChange" active-color="#0981ff" inactive-color="#ccc">
          </el-switch>
        </div>
      </div>
      <!--  比例 -->
      <div class="scale_box">
        <div class="Subsidy_header">
          <div style="display: flex;align-items: center;">
            <img src="../../assets/images/icon_wwfwl5.png" alt="">
            <p>平台补贴加价规则 <span style="color: #F76453;font-size: 14px;">说明：仅针对XM、一根线渠道订单，若启用后需要保证平台账户有余额</span></p>
          </div>
          <el-button icon="el-icon-plus" @click="updatePerruler('add')" size="small" type="primary">添加规则</el-button>
        </div>
        <div class="scale_table">
          <el-table :data="subsidyfigData" style="width: 100%" border
            :header-cell-style="{ background: '#E4ECFD', padding: 0 }">
            <el-table-column type="index" label="序号" width="50" align="center">
            </el-table-column>
            <el-table-column prop="date" label="首次推送报价区间" align="center">
              <template slot-scope="{row}">
                {{ row.lower }}元≤区间＜{{ row.upper }}元
              </template>
            </el-table-column>
            <el-table-column prop="name" label="议价加价限额" align="center">
              <template slot-scope="{row}">
                {{ row.expPriceLimit }}元
              </template>
            </el-table-column>
            <el-table-column prop="address" label="限额加价范围" align="center">
              <template slot-scope="{row}">
                1元≤区间≤{{ row.expPriceLimit }}元
              </template>
            </el-table-column>
            <el-table-column prop="address" label="超额加价范围" align="center">
              <template slot-scope="{row}">
                区间>{{ row.expPriceLimit }}元
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="{row}">
                <el-button size="mini" @click="updatePerruler('up', row)">编辑</el-button>
                <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 保存 -->
    <div style="padding-left: 20px;padding-top: 30px;" v-if="clickShow === '02'">
      <el-button @click="getchxExpconfig()">取消</el-button>
      <el-button type="primary" @click="chxExpsave">保存</el-button>
    </div>
    <!--内容-->
    <div class="inner" v-if="clickShow === '03'">
      <div class="opening_box">
        <div class="tip-header">
          <div>
            <img src="../../assets/images/icon_ygtjslb_4.png" alt="">
          </div>
          <p>是否启用虚拟报价</p>
        </div>
        <div class="opening_switch">
          <el-switch v-model="mockInfo.isOpen" active-value="01" inactive-value="02" active-color="#0981ff" inactive-color="#ccc" />
        </div>
      </div>
      <div class="terrace_box">
        <div class="tip-header">
          <div>
            <img src="../../assets/images/icon_ygtjslb_4.png" alt="">
          </div>
          <p>虚拟报价订单范围</p>
        </div>
        <div class="content-box">
          <div class="checkbox-box">
            订单分类：
            <el-checkbox v-for="item in mockInfo.typeScope" v-model="item.isSelected" :key="item.id">{{item.scopeName}}</el-checkbox>
          </div>
          <div class="checkbox-box">
            开机机况：
            <el-checkbox v-for="item in mockInfo.startScope" v-model="item.isSelected" :key="item.id">{{item.scopeName}}</el-checkbox>
          </div>
        </div>
      </div>
      <div class="terrace_box">
        <div class="tip-header">
          <div>
            <img src="../../assets/images/icon_ygtjslb_4.png" alt="">
          </div>
          <p>虚拟报价条件</p>
        </div>
        <div class="content-box">
          出价回收商数量：至少
          <el-select v-model="mockInfo.merchantNum" @change="mockInfo.quoteStandard = ''">
            <el-option label="1个" :value="1" />
            <el-option label="2个" :value="2" />
            <el-option label="3个" :value="3" />
          </el-select>
          商家出价（不含拒绝报价）时，可触发虚拟报价
        </div>
      </div>
      <div class="terrace_box">
        <div class="tip-header">
          <div>
            <img src="../../assets/images/icon_ygtjslb_4.png" alt="">
          </div>
          <p>虚拟报价设置</p>
        </div>
        <div class="content-box" style="margin-bottom:10px">
          虚拟报价计算基数：以平台
          <el-select v-model="mockInfo.quoteStandard">
            <el-option v-for="item in quoteStandardOptions" :key="item.value" :label="item.label" :value="item.value" />
            <!-- <el-option label="最高报价" value="01" />
            <el-option label="第二高价" value="02" />
            <el-option label="第三高价" value="03" /> -->
          </el-select>
          为基数计算虚拟报价金额
        </div>
        <div class="content-box title-box" style="margin-bottom:15px">
          <div>
            虚拟报价规则
            <span class="red-description">说明：根据以下规则计算虚拟报价范围，若该商家触发虚拟报价，则系统自动从虚拟报价范围中取一个整数进行报价。虚拟报价仍需扣除渠道服务费返利红包等</span>
          </div>
          <el-button icon="el-icon-plus" @click="$refs['virtualQuoteRuleDia'].open()" size="small" type="primary">添加规则</el-button>
        </div>
        <div class="content-box">
          <el-table :data="mockInfo.regionList" style="width: 100%" border :header-cell-style="{ background: '#E4ECFD', padding: 0 }">
            <el-table-column label="序号" type="index" align="center" />
            <el-table-column label="报价金额" align="center">
              <template slot-scope="{ row }">
                {{row.lower}}元≤区间&lt;{{row.upper}}元
              </template>
            </el-table-column>
            <el-table-column label="虚拟报价起始值下浮比例" align="center">
              <template slot-scope="{ row }">
                {{row.lowerRate}}%
              </template>
            </el-table-column>
            <el-table-column label="最多下浮金额" prop="lowerLimit" align="center" />
            <el-table-column label="虚拟报价结束值下浮比例" align="center">
              <template slot-scope="{ row }">
                {{row.upperRate}}%
              </template>
            </el-table-column>
            <el-table-column label="最多下浮金额" prop="upperLimit" align="center" />
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row, $index }">
                <el-button type="primary" size="mini" @click="$refs['virtualQuoteRuleDia'].open(row)">编辑</el-button>
                <el-button type="danger" size="mini" @click="delItem($index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 保存 -->
    <div style="padding-left: 20px;padding-top: 30px;" v-if="clickShow === '03'">
      <el-button @click="getMockConfig()">取消</el-button>
      <el-button type="primary" @click="mockShow = true">保存</el-button>
    </div>
    <!--内容-->
    <div class="inner" style="padding: 0;border-radius: 0" v-if="clickShow === '04'">
      <el-tabs v-model="bargainActiveName" type="card" :before-leave="beforeBargainHandle">
        <el-tab-pane v-for="item in bargainActiveOption" :key="item.name" :label="item.label" :name="item.name"></el-tab-pane>
      </el-tabs>
      <div class="opening_box" v-loading="expInfoLoading" style="padding: 20px;border: 1px solid #E4ECFD;border-top: none;">
        <el-button
          size="small"
          type="primary"
          @click="addRuleTab()"
        >添加规则</el-button>
        <el-tabs v-model="expRuleTabsValue" @tab-remove="removeRuleTab" @tab-click="ruleTabClick">
          <el-tab-pane
            v-for="item in expRuletableTabs"
            :key="item.ruleName"
            :label="item.ruleName"
            :name="item.ruleName"
            :closable="item.closable"
          >
          </el-tab-pane>
        </el-tabs>
        <div class="opening_switch" style="margin-top: 17px;padding-left:20px;">
          <span style="margin-right: 20px;">启用议价自动审批功能</span>
          <el-switch v-model="onRuleInfo.isAutoAudit" active-value="01" inactive-value="02" active-color="#0981ff" inactive-color="#ccc" />
        </div>
        <div class="terrace_box" style="border: none;">
          <div class="content-box" style="padding-left: 20px;">
            <div class="checkbox-box">
              设置自动审批金额：门店申请加价金额
              <el-tooltip class="item" effect="dark" content="加价金额=门店申请议价金额-议价前门店接收报价金额" placement="top">
                <img src="@/assets/images/why.png" style="width:16px;height:16px;margin: 0 5px;cursor:pointer" alt="">
              </el-tooltip>
              ≤ 
              <el-input-number :precision="0" style="margin: 0 10px;" size="small" v-model="onRuleInfo.autoAuditPrice" :min="0" :max="100" label=""></el-input-number>
              元时，平台自动补贴加价审批
            </div>
          </div>
          <div class="terrace_check" style="padding: 0 0 0 20px;">
            <p class="terrace_header">设置启用时间段<i @click="expTimeSetClick">设置</i>
            </p>
            <div class="terrace_tb_title">
              <p class="terrace_header">已添加启用商户（{{onRuleInfo.companyList?onRuleInfo.companyList.length:0}}个）</p>
              <el-button type="primary" size="small" @click="addMerchant">+添加商户</el-button>
            </div>
            <div class="terrace_table">
              <el-table :data="onRuleInfo.companyList" style="width: 100%;" :max-height="400"
                :header-cell-style="{ background: '#E4ECFD', padding: 0 }" border>
                <el-table-column
                  type="index"
                  align="center"
                  label="序号"
                  width="50"
                />
                <el-table-column v-for="item of bargainMerchantColumns" :prop="item.prop" :key="item.prop" align="center"
                  :label="item.label" :width="item.width">
                  <template v-slot="{ row }">
                    <p v-if="item.prop === 'action'" style="color: red;cursor: pointer" @click="bargainDel(row)">移除</p>
                    <p v-else>{{ row[item.prop] || '--' }}</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div v-if="adminAccInfo.adminAcc" style="margin-top: 20px;font-weight: bold; color: #333333; font-size: 14px;">
          <span style="margin-right: 50px;">最近修改人：{{adminAccInfo.adminName}}-{{adminAccInfo.adminAcc}}</span>
          <span>最近修改时间：{{adminAccInfo.updateTime}}</span>
        </div>
        <!-- 保存 -->
        <div style="padding-top: 30px;" v-if="clickShow === '04'">
          <el-button type="primary" @click="expSaveShow = true">保存</el-button>
        </div>
      </div>
    </div>
    
    <!-- 规则 -->
    <el-dialog title="查看规则" :visible.sync="ruleShow" width="700px">
      <div class="rule_box">
        <h2>加价规则</h2>
        <p>1.开启后仅零售通渠道订单支持加价，平台发起报价按照提交时商家最高成交价金额计算提交的报价；</p>
        <p>2.用于平台加价的回收商需要通过数据库配置。</p>
        <p>3.计算公式：平台报价=（1+加价比例）×最高商家成交价</p>
      </div>
      <div class="rule_box">
        <h2>手动加价</h2>
        <p>1.使用手动加价方式，平台人员可在订单列表自行挑选订单加价；</p>
        <p>2.若加价后，所有商家均为报价时，系统触发自动报价，则此时手动加价价格需要在自动报价基础上累加。</p>
        <p>3.手动加价后，在报价未推送之前可点击撤销加价。</p>
      </div>
      <div class="rule_box">
        <h2>自动加价</h2>
        <p>1.使用自动加价方式，在生效时间段内，满足条件的订单系统会自动加价</p>
        <p>
          2.订单存在以下情况不触发自动加价：
        <ul>
          <li>订单在2分40秒触发兜底报价，不再触发自动加价；</li>
          <li>订单在2分50秒触发自动报价，则不触发自动加价；</li>
          <li>同串号重评订单按原逻辑拉齐或由指定商家报价，不触发自动加价。</li>
        </ul>
        </p>
        <p>
          3.同串号重评订单，若预估价变低，上一单取价为自动加价，则本单需自动计算价格提交。自动加价商家报价需按预估价降低比例扣减后返回给采货侠。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ruleShow = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 加价时间 -->
    <GlobalDialog :show="timeShow" title="设置加价时间段" @submitPopupData="timeSave" :width="520" top="6%"
      @onClose="timeShow = false;" @resetPopupData="timeShow = false;">
      <div class="time_dialog_box">
        <p>说明：最多可添加3个时间段，时间段不可重合</p>
        <div class="time_inner" v-show="!time.length" style="text-align: center;margin-top: 20px;">
          <i class="el-icon-circle-plus-outline null_icon" @click="createTime" />
          <p>暂未设置，点击创建</p>
        </div>
        <div v-show="time.length">
          <el-form label-width="63px">
            <el-form-item :label="'时间段' + (index + 1)" v-for="(item, index) of time" :key="index">
              <div class="time_box">
                <el-time-select class="time_start" placeholder="起始时间" v-model="time[index][0]" :clearable="false"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00',
                    maxTime: getMaxAndMin('max', time[index][1])
                  }">
                </el-time-select>
                <el-time-select :clearable="false" class="time_end" placeholder="起始时间" v-model="time[index][1]"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00',
                    minTime: getMaxAndMin('min', time[index][0])
                  }">
                </el-time-select>
                <i v-show="index < 2" class="el-icon-circle-plus-outline exist_icon" @click="createTime" />
                <i class="el-icon-remove-outline exist_icon exist_icon_del" @click="deleteTime(index)"></i>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </GlobalDialog>
    <!-- 门店list -->
    <GlobalDialog :show="storeListShow" title="添加门店" @submitPopupData="storeSave" :width="1000" top="6%"
      @onClose="storeListShow = false; $refs.searchForm.resetFields()"
      @resetPopupData="storeListShow = false; $refs.searchForm.resetFields()">
      <div class="store_list_box">
        <p>说明：已过滤已添加门店</p>
        <el-form :model="searchValue" inline size="mini" ref="searchForm">
          <el-form-item label="门店名称" prop="storeName">
            <el-input v-model="searchValue.storeName" clearable placeholder="请输入门店名称" />
          </el-form-item>
          <el-form-item label="门店标签" prop="tagId">
            <el-select v-model="searchValue.tagId" clearable placeholder="请选择门店标签">
              <el-option v-for="o in tagSelect" :key="o.value" :label="o.label" :value="o.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="区域/总监" prop="tagId">
            <el-select v-model="searchValue.inspectorId" clearable placeholder="请选择区域/总监">
              <el-option v-for="o in areaSelect" :key="o.value" :label="o.label" :value="o.value" />
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 30px;">
            <el-button type="primary" @click="searchClick('ruleForm')">查询</el-button>
          </el-form-item>
        </el-form>
        <!-- 表 -->
        <el-table border ref="multipleTable" :data="storeList" style="width: 100%"
          :header-cell-style="{ padding: 0, background: '#E4ECFD' }" @select="storeSelect" @select-all="storeAll">
          <el-table-column type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column v-for="item of storeColumnsDialog" :prop="item.prop" :key="item.prop" align="center"
            :label="item.label" :width="item.width">
            <template v-slot="{ row }">
              <p v-if="item.prop === 'action'" style="color: red;cursor: pointer">删除</p>
              <div v-else-if="item.prop === 'tagList'" style="overflow:hidden;">
                <div v-show="Array.isArray(row[item.prop]) && row[item.prop].length">
                  <p class="terrace_tag" :title="item.tagName" v-for="(item, index) in row[item.prop]" :key="index">
                    {{ item.tagName }}</p>
                </div>
                <div v-show="!(Array.isArray(row[item.prop]) && row[item.prop].length)">--</div>
              </div>
              <p v-else-if="item.prop === 'quoteMerchants'" style="color: #0981FF;">{{ row[item.prop] }}</p>
              <p v-else>{{ row[item.prop] }}</p>
            </template>
          </el-table-column>
        </el-table>
        <!--        @selection-change="handleSelectionChange"-->
        <!-- 分页 -->
        <div style="overflow:hidden;">
          <Pagination :meta="meta" :marginNum="10"
            @sizeChange="(v) => { meta = { ...meta, pageSize: v }; param = { ...param, pageSize: v } }"
            @pageChange="(v) => { meta = { ...meta, pageNum: v }; param = { ...param, pageNum: v } }" />
        </div>
      </div>
    </GlobalDialog>
    <!-- 加价规则设置 -->
    <GlobalDialog title="编辑加价规则" :show="editShow" @submitPopupData="editSave" :width="500" top="6%"
      @onClose="editShow = false;" @resetPopupData="editShow = false;">
      <el-form :model="editValue" :rules="editRule" ref="editForm" size="small">
        <div class="price_interval">
          <el-form-item label="最高报价区间" prop="lower" label-width="100px">
            <el-input v-model="editValue.lower" clearable type="number" min="0" disabled />
          </el-form-item>
          <el-form-item prop="upper">
            <el-input v-model="editValue.upper" clearable type="number" min="0" disabled />
          </el-form-item>
        </div>
        <el-form-item label="加价比例" prop="markUpRate" label-width="80px">
          <el-input v-model="editValue.markUpRate" @input="markUpRateInput" clearable type="number" min="1" max="100">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="加价上限" prop="markUpLimit" label-width="80px">
          <el-input v-model="editValue.markUpLimit" clearable maxlength="5" min="1"
            @input="(val) => (editValue.markUpLimit = val.replace(/\D/g, '').replace(/^0{1,}/g, ''))" />
        </el-form-item>
      </el-form>
    </GlobalDialog>
    <!-- 额外补贴规则设置 -->
    <GlobalDialog :title="perTitle" :show="perShow" @submitPopupData="perSave" :width="500" top="6%"
      @onClose="perShow = false;" @resetPopupData="perShow = false;">
      <el-form :model="perquisite" :rules="perRule" ref="editForm" size="small">
        <div class="price_interval">
          <el-form-item label="最高报价区间" prop="lower" label-width="100px">
            <el-input v-model="perquisite.lower" clearable type="number" min="0" />
          </el-form-item>
          <el-form-item prop="upper">
            <el-input v-model="perquisite.upper" clearable type="number" min="0" />
          </el-form-item>
        </div>
        <el-form-item label="加价限额" prop="expPriceLimit" label-width="80px">
          <el-input v-model="perquisite.expPriceLimit" clearable maxlength="5" min="1"
            @input="(val) => (perquisite.expPriceLimit = val.replace(/\D/g, '').replace(/^0{1,}/g, ''))" />
        </el-form-item>
      </el-form>
    </GlobalDialog>
    <GlobalDialog title="保存配置提示" :show="codeShow" @submitPopupData="codeSubmit" :width="500" top="6%"
      @onClose="codeShow = false; code = ''" @resetPopupData="codeShow = false; code = ''">
      <p style="font-size: 16px;text-align: center;color: #000;margin-bottom: 20px;">是否确认修改平台加价配置？</p>
      <el-form>
        <el-form-item label="动态口令" label-width="80px">
          <el-input placeholder="请输入谷歌动态口令" v-model="code" clearable />
        </el-form-item>
      </el-form>
    </GlobalDialog>
    <GlobalDialog title="保存配置提示" :show="chxShow" @submitPopupData="chXSubmit" :width="500" top="6%"
      @onClose="chxShow = false; code = ''" @resetPopupData="chxShow = false; code = ''">
      <p style="font-size: 16px;text-align: center;color: #000;margin-bottom: 20px;">是否确认修改平台补贴加价配置？</p>
      <el-form>
        <el-form-item label="动态口令" label-width="80px">
          <el-input placeholder="请输入谷歌动态口令" v-model="code" clearable />
        </el-form-item>
      </el-form>
    </GlobalDialog>
    <GlobalDialog title="保存配置提示" :show="mockShow" @submitPopupData="saveMock" :width="500" top="6%"
      @onClose="mockShow = false; code = ''" @resetPopupData="mockShow = false; code = ''">
      <p style="font-size: 16px;text-align: center;color: #000;margin-bottom: 20px;">是否确认修改虚拟报价配置?</p>
      <el-form>
        <el-form-item label="动态口令" label-width="80px">
          <el-input placeholder="请输入谷歌动态口令" v-model="code" clearable />
        </el-form-item>
      </el-form>
    </GlobalDialog>
    <GlobalDialog title="保存配置提示" :show="expSaveShow" @submitPopupData="saveExpMock" :width="500" top="6%"
      @onClose="expSaveShow = false; code = ''" @resetPopupData="expSaveShow = false; code = ''">
      <p style="font-size: 16px;text-align: center;color: #000;margin-bottom: 20px;">是否确认保存<span style="color: #0981FF">{{matchedLabel}}</span>的议价配置？</p>
      <el-form>
        <el-form-item label="动态口令" label-width="80px">
          <el-input placeholder="请输入谷歌动态口令" v-model="code" clearable />
        </el-form-item>
      </el-form>
    </GlobalDialog>
    <GlobalDialog title="操作" :show="expTabSaveShow" @submitPopupData="saveExpMock" :width="520" top="6%"
      @onClose="expTabSaveShow = false; code = '';" @resetPopupData="expTabSaveShowCancel">
      <p style="font-size: 16px;text-align: center;color: #000;margin-bottom: 20px;">切换页面将无法保存当前配置，是否先保存<span style="color: #0981FF">{{matchedLabel}}</span>议价配置？</p>
      <el-form>
        <el-form-item label="动态口令" label-width="80px">
          <el-input placeholder="请输入谷歌动态口令" v-model="code" clearable />
        </el-form-item>
      </el-form>
    </GlobalDialog>
    <VirtualQuoteRuleDia ref="virtualQuoteRuleDia" @confirm="ruleChangeHandler" />
    <!-- 门店list -->
    <GlobalDialog :show="merchantListShow" title="添加商户" @submitPopupData="merchantSave" :width="1000" top="6%"
      @onClose="merchantListShow = false"
      @resetPopupData="merchantListShow = false">
      <el-form :model="merchantParam" inline size="mini" ref="searchForm">
        <el-form-item label="商户名称">
          <el-select v-model="merchantParam.companyId" filterable clearable placeholder="请输入商户名称查询">
            <el-option v-for="item in storeSelectList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input
            placeholder="请输入联系人姓名"
            v-model="merchantParam.contact"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            placeholder="请输入联系电话"
            v-model="merchantParam.phone"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 30px;">
          <el-button type="primary" @click="searchMerchantClick">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-if="merchantListShow"
        ref="addMerchantDia"
        :data="merchantTableData"
        v-loading="merchantTableLoading"
        tooltip-effect="dark"
        border
        @selection-change="handSelectMerchant"
        :row-key="
          (row) => {
            return row.id;
          }
        "
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
        />
        <el-table-column v-for="item of bargainMerchantColumnsDia" :prop="item.prop" :key="item.prop" align="center"
          :label="item.label" :width="item.width">
          <template v-slot="{ row }">
            <p>{{ row[item.prop] || '--' }}</p>
          </template>
        </el-table-column>
      </el-table>
    </GlobalDialog>
    <el-dialog
      title="添加规则"
      :visible.sync="addRuleDiaShow"
      width="400px"
      :close-on-click-modal='false'
      @closed="addRuleDiaShowClosed">
      <div>
        <p style="color: #F76453;margin-bottom: 10px;">说明：添加新规则后可设置不同使用商户</p>
        <el-input
          placeholder="请输入规则名称，最多8个字"
          v-model="addRuleName"
          maxlength="8"
          size="small"
          clearable>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addRuleDiaShow = false">取 消</el-button>
        <el-button type="primary" @click="addRuleDiaSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 议价配置时间 -->
    <el-dialog
      title="设置时间段"
      :visible.sync="expTimeDiaShow"
      width="500px"
      :close-on-click-modal='false'
      @closed="expTimeDiaClosed">
      <div class="time_dialog_box">
        <p>说明：最多可添加3个时间段，时间段不可重合</p>
        <div class="time_inner" v-show="!expTimeDia.length" style="text-align: center;margin-top: 20px;">
          <i class="el-icon-circle-plus-outline null_icon" @click="createExpTime" />
          <p>暂未设置，点击创建</p>
        </div>
        <div v-show="expTimeDia.length">
          <el-form label-width="63px">
            <el-form-item :label="'时间段' + (index + 1)" v-for="(item, index) of expTimeDia" :key="index">
              <div class="time_box">
                <el-time-select class="time_start" placeholder="起始时间" v-model="expTimeDia[index][0]" :clearable="false"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00',
                    maxTime: getMaxAndMin('max', expTimeDia[index][1])
                  }">
                </el-time-select>
                <el-time-select :clearable="false" class="time_end" placeholder="起始时间" v-model="expTimeDia[index][1]"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00',
                    minTime: getMaxAndMin('min', expTimeDia[index][0])
                  }">
                </el-time-select>
                <i v-show="index < 2" class="el-icon-circle-plus-outline exist_icon" @click="createExpTime" />
                <i class="el-icon-remove-outline exist_icon exist_icon_del" @click="deleteExpTime(index)"></i>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="expTimeDiaShow = false">取消</el-button>
        <el-button type="primary" @click="expTimeDiaSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import GlobalInfoBar from "@/components/global/components/GlobalInfoBar.vue";
import _api from "@/utils/request";
import GlobalDialog from "@/components/global/components/GlobalDialog.vue";
import moment from "moment";
import {
  getIndex,
  getMeta,
  pageSize,
  setValue,
  timeOverlay,
} from "@/utils/common";
import Pagination from "@/components/common/Pagination.vue";
import VirtualQuoteRuleDia from './components/VirtualQuoteRuleDia.vue';

export default {
  components: { GlobalDialog, GlobalInfoBar, Pagination, VirtualQuoteRuleDia },
  data() {
    return {
      perTitle: "添加规则",
      subsidyfigData: [],
      isopenSubsidy: false,
      perShow: false,
      perType: '',
      updataId: "",
      clickShow: "01",
      // 口令
      code: "",
      // 平台加价控制
      openingValue: false,
      // 谷歌动态口令提示
      codeShow: false,
      chxShow: false,
      mockShow: false,
      // 方式
      wayValue: "",
      // 规则弹窗
      ruleShow: false,
      //加价时间弹窗
      timeShow: false,
      // 门店数据
      storeData: [],
      // 门店list
      storeList: [],
      // 门店list弹窗
      storeListShow: false,
      // 每次选择的新数据
      newStoreList: [],
      // 配置信息
      //区域下拉
      areaSelect: [],
      // 标签下拉
      tagSelect: [],
      // 编辑弹窗
      editShow: false,
      // 配置信息
      configData: {
        regionTimeList: [],
      },
      // 当前编辑项
      editCurrent: {},
      // 初始值
      // searchInit:{
      //   storeName:'',
      //   tagId:"",
      //   inspectorId:''
      // },
      // tagSelects: [],
      // areaSelects: [],
      param: {
        // 初始值
        // 当前页数
        pageNum: 1,
        // 当前条数
        pageSize,
      },
      // 分页数据
      meta: {
        total: 0,
        pageSize,
        pageNum: 1,
      },
      // 门店筛选参数
      searchValue: {
        storeName: "",
        tagId: "",
        inspectorId: "",
      },
      // 编辑表单model
      editValue: {
        // 最低
        lower: "",
        // 最高
        upper: "",
        // 加价比例
        markUpRate: "",
        // 加价上限
        markUpLimit: "",
      },
      perquisite: {
        // 最低
        lower: "",
        // 最高
        upper: "",
        // 加价上限
        expPriceLimit: "",
      },
      // 编辑表单强验证
      perRule: {
        expPriceLimit: [
          {
            required: true,
            message: "请输入加价限额",
            trigger: "blur",
          },
        ],
      },
      // 编辑表单强验证
      editRule: {
        markUpRate: [
          {
            required: true,
            message: "请输入加价比例",
            trigger: "blur",
          },
        ],
        markUpLimit: [
          {
            required: true,
            message: "请输入加价上限",
            trigger: "blur",
          },
        ],
      },
      // 测试
      time: [],
      // 商家表头
      storeColumns: [
        {
          prop: "index",
          label: "序号",
          width: "70px",
        },
        {
          prop: "storeName",
          label: "门店名称",
        },
        {
          prop: "areaName",
          label: "区域/总监",
        },
        {
          prop: "tagList",
          label: "门店标签",
        },
        {
          prop: "quoteMerchants",
          label: "配置报价商家",
        },
        {
          prop: "action",
          label: "操作",
        },
      ],
      // 比例
      scaleColumns: [
        {
          prop: "index",
          label: "序号",
          width: "70px",
        },
        {
          prop: "range",
          label: "最高成交价区间",
        },
        {
          prop: "markUpRate",
          label: "加价比例",
        },
        {
          prop: "markUpLimit",
          label: "加价上限",
        },
        {
          prop: "action",
          label: "操作",
        },
      ],
      // 门店表（弹窗）
      storeColumnsDialog: [
        {
          prop: "index",
          label: "序号",
          width: "70px",
        },
        {
          prop: "areaName",
          label: "区域/总监",
        },
        {
          prop: "storeName",
          label: "门店名称",
        },
        {
          prop: "tagList",
          label: "门店标签",
        },
      ],
      checkList: [],
      classList: [],
      mockInfo: {
        isOpen: '02',
        typeScope: [],
        startScope: [],
        merchantNum: '',
        quoteStandard: '',
        regionList: []
      },
      // 议价设置
      bargainActiveName: "xm",
      bargainActiveOption: [
        {
          label: '小米',
          name: 'xm'
        },
        {
          label: '一根线',
          name: 'ygx'
        },
        {
          label: '闪回',
          name: 'honor'
        },
        {
          label: '采货侠新渠道',
          name: 'chx'
        }
      ],
      // 商家表头
      bargainMerchantColumns: [
        {
          prop: "companyName",
          label: "商户名称",
        },
        {
          prop: "contact",
          label: "联系人",
        },
        {
          prop: "phone",
          label: "联系电话",
        },
        {
          prop: "action",
          label: "操作",
        },
      ],
      // 弹框里商家表头
      bargainMerchantColumnsDia: [
        {
          prop: "companyName",
          label: "商户名称",
        },
        {
          prop: "contact",
          label: "联系人",
        },
        {
          prop: "phone",
          label: "联系电话",
        },
      ],
      newMerchantList: [],
      merchantListShow: false,
      merchantTableData: [],
      merchantTableLoading: false,
      merchantParam: {
        companyId: "",
        contact: "",
        phone: "",
      },
      expInfoLoading: false,
      expRuleTabsValue: '',
      expRuletableTabs: [],
      onRuleInfo: {},
      addRuleDiaShow: false,
      addRuleName: "",
      adminAccInfo: {},
      expTimeDiaShow: false,
      expTimeDia: [],
      expSaveShow: false,
      storeSelectList: [],
      ruleCompanyIds: [],
      expTabSaveShow: false,
      nextTab: ''
    };
  },
  // 初始钩
  created() {
    // 获取配置
    this.getData();
    // 标签下拉
    _api.getStoreTagSelects().then((r) => {
      if (r.code === 1) {
        r.data = Array.isArray(r.data) ? r.data : [];
        this.tagSelect = r.data.map((v) => {
          return {
            value: v.id,
            label: v.name,
          };
        });
      }
    });
    // 总监下拉
    _api.getInspectorAreaSelect().then((r) => {
      if (r.code === 1) {
        r.data = Array.isArray(r.data) ? r.data : [];
        this.areaSelect = r.data.map((v) => {
          return {
            value: v.staffId,
            label:
              (v.staffName ? v.staffName : "") +
              (v.areaName ? "-" + v.areaName : ""),
          };
        });
      }
    });
    _api.selectList().then(res => {
      this.classList = res.data
    })
  },
  watch: {
    // 更改加价操作
    openingValue(v) {
      if (v) {
        this.configData.isOpen = "01";
      } else {
        this.configData.isOpen = "02";
      }
    },
    // 请求体
    param() {
      this.getStoreList();
    },
  },
  computed: {
    quoteStandardOptions() {
      const options = [
        { label: '最高报价', value: '01' },
        { label: '第二高价', value: '02' },
        { label: '第三高价', value: '03' }
      ]
      if (this.mockInfo.merchantNum) {
        return options.filter(item => Number(item.value) <= this.mockInfo.merchantNum)
      }
      return []
    },
    matchedLabel() {
      return this.bargainActiveOption.find(option => option.name === this.bargainActiveName)?.label;
    },
  },
  methods: {
    subsidyChange(val) {
      console.log(val);
    },
    getchxExpconfig() {
      _api.chxExpconfig().then(res => {
        this.isopenSubsidy = res.data.isOpen == '01' ? true : false;
        this.subsidyfigData = res.data.regionList || []
      })
    },
    getMockConfig() {
      _api.mockQuoteInfo().then(res => {
        res.data.regionList.forEach((item, index) => {
          item.id = index + 1
        })
        this.mockInfo = res.data
      })
    },
    BtnClick(type) {
      this.clickShow = type;
      switch(type) {
        case '01':
          this.getData();
          break
        case '02':
          this.getchxExpconfig()
          break;
        case '03':
          this.getMockConfig()
          break
        case '04':
          this.getExpConfigInfo()
          break
      }
    },
    delItem(i) {
      this.mockInfo.regionList.splice(i, 1)
    },
    ruleChangeHandler(type, ruleItem) {
      if (type == 'add') {
        this.mockInfo.regionList.push({ ...ruleItem, _t: Math.random()})
      } else {
        const { id, _t } = ruleItem
        const itemIndex = this.mockInfo.regionList.findIndex(item => {
          if (id) return item.id === id
          return _t === item._t
        })
        this.$set(this.mockInfo.regionList, itemIndex, ruleItem)
      }
    },
    saveMock() {
      if (!this.code) {
        return this.$message.error("请输入动态口令");
      }
      _api.mockQuoteSave({
        ...this.mockInfo,
        command: this.code
      }).then(res => {
        this.$message.success("配置成功");
        this.mockShow = false
        this.getMockConfig()
      })
    },
    // 设置加价比例
    markUpRateInput(v) {
      const i = v.lastIndexOf(".");
      let str = "";
      if (i >= 0) {
        str = v.substring(0, i + 3);
      } else {
        if (v.length >= 3) {
          str = 100;
        } else {
          str = v;
        }
      }
      this.editValue.markUpRate = str;
    },
    // 配置请求
    getData() {
      _api.makeupConfigGet().then((r) => {
        if (r.code === 1) {
          const data = {
            ...r.data,
            // 加价比例
            regionList: r.data.regionList.map((v, i) => {
              v = setValue(v);
              // configData.storeList
              return {
                ...v,
                index: i + 1,
                range:
                  `${v.lower}元<区间≤${v.upper}元` === "--元<区间≤--元"
                    ? `--`
                    : `${v.lower}元<区间≤${v.upper}元`,
                markUpRate: v.markUpRate === "--" ? "--" : v.markUpRate + "%",
                markUpLimit:
                  v.markUpLimit === "--" ? "--" : "￥" + v.markUpLimit,
              };
            }),
            // 门店列表
            storeList: r.data.storeList.map((v, i) => {
              v = setValue(v);
              return {
                ...v,
                index: i + 1,
                areaName:
                  v.inspectorName === "--"
                    ? v.areaName
                    : `${v.inspectorName}${v.areaName === "--" ? "" : "-" + v.areaName
                    }`,
              };
            }),
          };
          // this.time = r.data.regionTimeList.map(v => {
          //   return [new Date('2022-05-29 ' + v.startTime), new Date('2022-05-29 ' + v.endTime)]
          // });
          this.time = r.data.regionTimeList.map((v) => {
            return [v.startTime, v.endTime];
          });

          this.configData = data;
          // 是否平台加价
          this.openingValue = this.configData.isOpen === "01";
        }
      });
    },
    // 获取门店列表
    getStoreList() {
      const storeIds = this.configData.storeList.map((v) => v.storeId);
      _api.makeupFindStore({ ...this.param, storeIds }).then((r) => {
        this.meta = getMeta(r.data.total, r.data.current, r.data.size);
        if (r.code === 1) {
          this.storeList = r.data.records.map((v, i) => {
            // 判断数据，设置空--
            v = setValue(v);
            return {
              ...v,
              index: getIndex(r.data.current, r.data.size, i),
              // (v.inspectorName === '--' ? '' : v.inspectorName) + (v.areaName === '--' ? '' : '-' + v.areaName)
              // v.inspectorName === '--' ? v.areaName : `${v.inspectorName}${v.areaName === '--' ? '' : ('-' + v.areaName)}`
              areaName:
                v.inspectorName === "--"
                  ? v.areaName
                  : `${v.inspectorName}${v.areaName === "--" ? "" : "-" + v.areaName
                  }`,
            };
          });
          // 选中指定项
          if (this.newStoreList.length > 0) {
            this.newStoreList.forEach((v) => {
              const current = this.storeList.find(
                (item) => item.storeId === v.storeId
              );
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(current);
              });
            });
          }
        }
      });
    },
    //补贴保存
    chxExpsave() {
      this.chxShow = true;
    },
    // 保存
    save() {
      // // 自动加价时间判断
      if (this.configData.addType === "01") {
        const data = this.time.map((v) => {
          return {
            s: v[0],
            e: v[1],
          };
        });
        // 再次判断时间是否重叠
        if (!timeOverlay(data)) {
          return this.$message.error("时间存在重叠部分，请调整");
        }
        if (!this.time.length) {
          return this.$message.error("至少选择一个时间段");
        }
      }
      this.codeShow = true;
    },
    // 补贴配置提交
    chXSubmit() {
      if (!this.code) {
        return this.$message.error("请输入动态口令");
      }
      let chxParams = {
        "command": this.code,
        "isOpen": this.isopenSubsidy ? '01' : '00',
        "regions": this.subsidyfigData || []
      }
      console.log(chxParams);
      _api.saveChxExpconfig(chxParams).then((r) => {
        if (r.code === 1) {
          this.$message.success("配置成功");
          this.getchxExpconfig();
          this.chxShow = false;
          this.code = "";
        }
      });
    },
    // 配置提交
    codeSubmit() {
      if (!this.code) {
        return this.$message.error("请输入动态口令");
      }
      // 时间段
      let timeRegionList = [];
      let storeIds = [];
      storeIds = this.configData.storeList.map((v) => v.storeId);
      timeRegionList = this.time.map((v) => {
        return {
          startTime: v[0] ? v[0] : "",
          endTime: v[1] ? v[1] : "",
        };
      });

      //加价列表
      const regionList = this.configData.regionList.map((v) => {
        return {
          ...v,
          markUpLimit:
            v.markUpLimit === "--"
              ? null
              : parseFloat(v.markUpLimit.replace("￥", "")),
          markUpRate:
            v.markUpRate === "--"
              ? null
              : parseFloat(v.markUpRate.replace("%", "")),
        };
      });
      let params = {
        regionList,
        isOpen: this.configData.isOpen,
        makeUpType: this.configData.addType,
        command: this.code,
      };
      if (this.configData.addType === "01") {
        params = {
          ...params,
          storeIds,
          timeRegionList,
        };
      }
      _api.makeupConfigSave(params).then((r) => {
        if (r.code === 1) {
          this.$message.success("配置成功");
          this.getData();
          this.codeShow = false;
          this.code = "";
        }
      });
    },
    // 筛选
    searchClick() {
      this.param = {
        ...this.param,
        ...this.searchValue,
        pageNum: 1,
      };
    },
    // 弹窗
    timeSetClick() {
      if (this.time.length === 0) {
        this.time.push({
          startTime: "",
          endTime: "",
        });
      }
      this.timeShow = true;
    },
    // 创建时间
    createTime() {
      if (this.time.length > 2) {
        return this.$message.error("最多只能添加3个时间段");
      }
      this.time = this.time.concat({
        startTime: "",
        endTime: "",
      });
    },
    // 获取最大时间
    getMaxAndMin(type, v) {
      if (type === "max") {
        if (!v) {
          return "24:00";
        }
        return v.substring(0, v.length - 1) + "1";
      }
      if (type === "min") {
        if (!v) {
          return "";
        }
        // 是否需要减分钟
        if (v.indexOf("30") > 0) {
          return v.substring(0, 3) + "29";
        } else {
          let start = Number(v.substring(0, 2)) - 1;
          if (String(start).length === 1) {
            start = `0${start}`;
          }
          return start + ":59";
        }
      }
    },
    //删除时间
    deleteTime(i) {
      this.time.splice(i, 1);
    },
    // 保存时间
    timeSave() {
      const timeFilter = this.time.filter((v) => v[0] && v[1]);
      this.time = timeFilter;
      const data = timeFilter.map((v) => {
        return {
          s: v[0],
          e: v[1],
        };
      });
      if (!timeOverlay(data)) {
        return this.$message.error("时间存在重叠部分，请调整");
      }
      this.timeShow = false;
    },
    // 添加门店
    addStore() {
      this.newStoreList = [];
      this.storeListShow = true;
      // 获取添加门店列表
      this.param = {
        pageNum: 1,
        // 当前条数
        pageSize,
      };
    },
    // 确定门店添加操作
    storeSave() {
      this.storeListShow = false;
      this.configData.storeList = [
        ...this.configData.storeList,
        ...this.newStoreList,
      ].map((v, i) => {
        return {
          ...v,
          index: i + 1,
        };
      });
    },
    // 添加门店选择
    // handleSelectionChange(v) {
    //   this.newStoreList = v;
    // },
    // selectArr选择的（不包含未选择的），current当前操作的
    storeSelect(selectArr, current) {
      let selectData = [];
      selectArr.forEach((v) => {
        if (v !== undefined) {
          selectData.push(v);
        }
      });
      // 如果操作后且是选中
      if (selectData.find((v) => v.storeId === current.storeId)) {
        // 判断原数据是否存在此项
        if (!this.newStoreList.some((v) => v.storeId === current.storeId)) {
          // 不存在才push
          this.newStoreList.push(current);
        }
      } else {
        //先判断这项是否已存储
        if (this.newStoreList.some((v) => v.storeId === current.storeId)) {
          // 存在再删除，反之无操作
          this.newStoreList.splice(
            this.newStoreList.findIndex((v) => v.storeId === current.storeId),
            1
          );
        }
      }
      console.log(selectData);
    },
    storeAll(v) {
      if (!v.length) {
        return this.storeList.forEach((v, index) => {
          const i = this.newStoreList.findIndex(
            (item) => item.storeId === v.storeId
          );
          if (i >= 0) {
            this.newStoreList.splice(i, 1);
          }
        });
      }
      const newStoreList = Array.from(new Set([...this.newStoreList, ...v]));
      this.newStoreList = newStoreList;
    },
    // 删除
    del(row) {
      const i = this.configData.storeList.findIndex((v) => row === v);
      this.configData.storeList.splice(i, 1);
      this.configData.storeList = this.configData.storeList.map((v, i) => {
        return {
          ...v,
          index: i + 1,
        };
      });
    },
    // 编辑
    edit(row) {
      this.editShow = true;
      this.editCurrent = row;
      this.editValue = {
        lower: row.lower === "--" ? "" : Number(row.lower),
        upper: row.upper === "--" ? "" : Number(row.upper),
        markUpRate:
          row.markUpRate === "--"
            ? ""
            : Number(row.markUpRate.replace("%", "")),
        markUpLimit:
          row.markUpLimit === "--"
            ? ""
            : Number(row.markUpLimit.replace("￥", "")),
      };
    },
    updatePerruler(type, data) {
      this.perquisite = {
        // 最低
        lower: "",
        // 最高
        upper: "",
        // 加价上限
        expPriceLimit: "",
      };
      this.perType = type
      this.perTitle = type == 'add' ? '添加规则' : '编辑规则'
      if (type != 'add') {
        this.perquisite = JSON.parse(JSON.stringify(data))
        this.updataId = data.id
      }
      this.perShow = true
    },
    handleDelete(data) {
      console.log(data);
      this.subsidyfigData=this.subsidyfigData.filter(item=>item.id!=data.id)
    },
    // 加价规则表保存
    editSave() {
      this.$refs["editForm"].validate((v) => {
        // 通过验证
        if (v) {
          if (parseFloat(this.editValue.lower) < 0) {
            return this.$message.error("最低报价区间不能小于0");
          }
          if (parseFloat(this.editValue.upper) < 0) {
            return this.$message.error("最高报价区间不能小于0");
          }
          if (parseFloat(this.editValue.markUpRate) > 100) {
            return this.$message.error("加价比例不能超出100");
          }
          if (parseFloat(this.editValue.markUpRate) <= 0) {
            return this.$message.error("加价比例不能小于等于0");
          }
          if (parseFloat(this.editValue.markUpLimit) <= 0) {
            return this.$message.error("加价上限不能小于等于0");
          }
          this.configData.regionList = this.configData.regionList.map((v) => {
            if (v === this.editCurrent) {
              return {
                ...v,
                lower: parseFloat(this.editValue.lower),
                upper: parseFloat(this.editValue.upper),
                range:
                  `${this.editValue.lower}元<区间≤${this.editValue.upper}元` ===
                    "元<区间≤元"
                    ? `--`
                    : `${this.editValue.lower}元<区间≤${this.editValue.upper}元`,
                markUpRate: this.editValue.markUpRate + "%",
                markUpLimit: "￥" + this.editValue.markUpLimit,
              };
            }
            return v;
          });
          this.editShow = false;
        } else {
          return false;
        }
      });
    },
    // 加价限额规则表保存
    perSave() {
      this.$refs["editForm"].validate((v) => {
        // 通过验证
        if (v) {
          if (!this.perquisite.lower) {
            return this.$message.error("最低报价区间不能为空");
          }
          if (!this.perquisite.upper) {
            return this.$message.error("最高报价区间不能为空");
          }
          if (Number(this.perquisite.lower) < 0) {
            return this.$message.error("最低报价区间不能小于0");
          }
          if (Number(this.perquisite.upper) < 0) {
            return this.$message.error("最高报价区间不能小于0");
          }
          if (Number(this.perquisite.upper) < Number(this.perquisite.lower)) {
            return this.$message.error("最高报价区间不能小于最低报价区间");
          }
          if (Number(this.perquisite.expPriceLimit) <= 0) {
            return this.$message.error("加价上限不能小于等于0");
          }
          this.perShow = false;
          console.log(this.perquisite);
          if (this.perType == 'add') {
            this.subsidyfigData.push({ id: (Number(this.perquisite.upper) + 1), ...this.perquisite })
          } else {
            this.subsidyfigData.forEach((item, index) => {
              if (item.id == this.updataId) {
                item.lower = this.perquisite.lower
                // 最高
                item.upper = this.perquisite.upper
                // 加价上限
                item.expPriceLimit = this.perquisite.expPriceLimit
              }
            })
          }
          console.log(this.subsidyfigData);
        } else {
          return false;
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    addRuleTab() {
      this.addRuleDiaShow = true
    },
    removeRuleTab(targetName) {
      let ruleName = ""
      this.expRuletableTabs.forEach(item => {
        if (item.ruleName === targetName) {
          ruleName = item.ruleName
        }
      })
      let tipText = `确认要删除${ruleName}的全部设置数据吗？`
      this.$confirm(tipText, '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let tabs = this.expRuletableTabs;
        let bargainActiveName = this.expRuleTabsValue;
        if (bargainActiveName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.ruleName === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                bargainActiveName = nextTab.ruleName;
              }
            }
          });
        }
        this.expRuleTabsValue = bargainActiveName;
        this.expRuletableTabs = tabs.filter(tab => tab.ruleName !== targetName);
        this.expRuletableTabs.forEach(item => {
        if(item.ruleName === this.expRuleTabsValue){
          this.onRuleInfo = item
        }
      })
      })
    },
    ruleTabClick(e) {
      console.log(e);
      this.expRuletableTabs.forEach(item => {
        if(item.ruleName === e.name){
          this.expRuleTabsValue = item.ruleName
          this.onRuleInfo = item
        }
      })
    },
    addRuleDiaShowClosed() {
      this.addRuleName = ""
    },
    addRuleDiaSure() {
      if(!this.addRuleName.trim()){
        return this.$message.error('请输入规则名称')
      }
      let ruleNameArr = []
      this.expRuletableTabs.forEach(item => {
        ruleNameArr.push(item.ruleName)
      })
      if(ruleNameArr.includes(this.addRuleName)){
        return this.$message.error('规则名称不能重复')
      }
      let addbj = {
        ruleName: this.addRuleName,
        ruleCode: '',
        isAutoAudit: '02',
        companyIdList: [],
        companyList: [],
        autoAuditPrice: 0,
        times: [],
        closable: true,
      }
      this.expRuletableTabs.push(addbj);
      this.expRuleTabsValue = this.addRuleName;
      this.onRuleInfo = addbj
      this.addRuleDiaShow = false
    },
    handSelectMerchant(selection, row) {
      this.newMerchantList = selection;
    },
    addMerchant() {
      this.newMerchantList = [];
      this.merchantListShow = true;
      this.getStoreSelectList()
      this.searchMerchantClick()
    },
    searchMerchantClick() {
      this.merchantTableLoading = true
      // 获取添加门店列表
      this.merchantParam.outType = this.bargainActiveName
      _api.getGeneralList(this.merchantParam).then(res => {
        if(res.code === 1){
          console.log(this.ruleCompanyIds,'ruleCompanyIdsruleCompanyIdsruleCompanyIds');

          if(this.ruleCompanyIds && this.ruleCompanyIds.length){
            res.data = res.data.filter(item => !this.ruleCompanyIds.includes(item.companyId));
          }
          this.merchantTableData = res.data
        }
      }).finally(() => {
        this.merchantTableLoading = false
      });
    },
    
    merchantSave() {
      if(!this.newMerchantList.length){
        return this.$message.error('请选择商户')
      }
      this.newMerchantList.forEach(item => {
        this.onRuleInfo.companyIdList.push(item.companyId)
        this.ruleCompanyIds.push(item.companyId)
      })
      this.onRuleInfo.companyList.push(...this.newMerchantList)
      
      console.log(this.onRuleInfo.companyIdList,'this.onRuleInfo.companyIdList');
      this.merchantListShow = false
    },
    // 获取议价配置
    getExpConfigInfo() {
      this.expInfoLoading = true
      _api.expConfigInfo({outType: this.bargainActiveName}).then(res => {
        if(res.code == 1){
          console.log(res);
          this.adminAccInfo = {
            adminAcc: res.data.adminAcc,
            adminName: res.data.adminName,
            updateTime: res.data.updateTime,
          }
          this.ruleCompanyIds = []
            res.data.rules.forEach(item => {
            if(!item.isDefault){
              item.closable = true
            }else{
              item.closable = false
            }
            item.companyIdList = item.companyIdList || []
            this.ruleCompanyIds.push(...item.companyIdList)
          })
          this.expRuletableTabs = res.data.rules||[]
          this.expRuleTabsValue = this.expRuletableTabs[0].ruleName
          this.onRuleInfo = this.expRuletableTabs[0]
        }
      }).finally(() => {
        this.expInfoLoading = false
      });
    },
    bargainDel(row) {
      this.onRuleInfo.companyList = this.onRuleInfo.companyList.filter(item => row.companyId !==item.companyId);
      this.onRuleInfo.companyIdList = this.onRuleInfo.companyIdList.filter(item => row.companyId !==item);
      this.ruleCompanyIds = this.ruleCompanyIds.filter(item => row.companyId !==item);
    },
    expTimeSetClick() {
      this.expTimeDia = this.onRuleInfo.times.map((v) => {
        return [v.startTime, v.endTime];
      })
      this.expTimeDiaShow = true
    },
    // 创建时间
    createExpTime() {
      if (this.expTimeDia.length > 2) {
        return this.$message.error("最多只能添加3个时间段");
      }
      this.expTimeDia = this.expTimeDia.concat({
        startTime: "",
        endTime: "",
      });
    },
    //删除时间
    deleteExpTime(i) {
      this.expTimeDia.splice(i, 1);
    },
    expTimeDiaClosed() {
      this.expTimeDia = []
    },
    expTimeDiaSure() {
      if (!this.expTimeDia.length) {
        return this.$message.error("至少选择一个时间段");
      }
      let flag = false
      this.expTimeDia.forEach(item => {
        if(!item[0] || !item[1]){
          flag = true
        }
      })
      if (flag) {
        return this.$message.error("请补全时间段");
      }
      // 再次判断时间是否重叠
      const data = this.expTimeDia.map((v) => {
        return {
          s: v[0],
          e: v[1],
        };
      });
      if (!timeOverlay(data)) {
        return this.$message.error("时间存在重叠部分，请调整");
      }
      this.onRuleInfo.times = this.expTimeDia.map((v) => {
        return {
          startTime: v[0],
          endTime: v[1],
        };
      });
      this.expTimeDiaShow = false
    },
    saveExpMock() {
      if (!this.code) {
        return this.$message.error("请输入动态口令");
      }
      console.log(this.expRuletableTabs,'this.expRuletableTabs');
      let rules = []
      this.expRuletableTabs.forEach(item => {
        rules.push({
          autoAuditPrice: item.autoAuditPrice,
          companyIdList: item.companyIdList,
          isAutoAudit: item.isAutoAudit,
          ruleCode: item.ruleCode,
          ruleName: item.ruleName,
          times: item.times,
        })
      })
      let result = rules.find(ele => {
        if (ele.isAutoAudit == '01' && (!ele.autoAuditPrice || !ele.times.length || !ele.companyIdList.length)) {
          return true; // 找到符合条件的元素，返回该元素
        }
      });
      if(result){
        if(!result.autoAuditPrice){
          return this.$message.error(`请填写(${result.ruleName})的自动审批金额`)
        }
        if(!result.times.length){
          return this.$message.error(`请添加(${result.ruleName})的启用时间段`)
        }
        if(!result.companyIdList.length){
          return this.$message.error(`请添加(${result.ruleName})的启用商户`)
        }
      }
      let params = {
        command: this.code,
        outType: this.bargainActiveName,
        rules,
      }
      _api.saveExpConfig(params).then(res => {
        if(res.code === 1){
          this.$message.success('保存成功')
          this.expSaveShow = false
          if(this.expTabSaveShow){
            this.bargainActiveName = this.nextTab
            this.expTabSaveShow = false
          }
          this.getExpConfigInfo()
        }
      })
    },
    getStoreSelectList(){
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.storeSelectList = res.data;
        }
      });
    },
    beforeBargainHandle(e) {
      this.nextTab = e
      if(this.bargainActiveName != this.nextTab){
        this.expTabSaveShow = true
        return false
      }
      
    },
    expTabSaveShowCancel() {
      this.expTabSaveShow = false;
      this.code = '';
      this.bargainActiveName = this.nextTab
      this.getExpConfigInfo()
    }
  },
};
</script>

<style scoped lang="scss">
.inner {
  width: 100%;
  background: #fff;
  border-radius: 20px;

  .opening_box {
    padding: 20px 0;
    border-bottom: 1px solid #edeffd;

    .opening_switch {
      margin-top: 6px;
      padding-left: 47px;
    }
  }
}

.Subsidy_header {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 20px;
    height: 20px;
  }

  p {
    font-size: 18px;
    margin-left: 7px;
    font-weight: 900;
    color: #333333;
  }
}

.tip-header {
  padding-left: 20px;
  display: flex;
  align-items: center;

  >div {
    height: 20px;

    >img {
      width: 20px;
      height: 20px;
    }
  }

  >p {
    font-size: 18px;
    margin-left: 7px;
    font-weight: 900;
    color: #333333;

    >i {
      display: inline-block;
      font-style: normal;
      color: #f76453;
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      border-bottom: 1px solid #f76453;
      //text-decoration: underline;
      transform: translateY(-3px);
      margin-left: 3px;
    }
  }
}

.terrace_box {
  margin-top: 17px;
  padding-bottom: 20px;
  border-bottom: 1px solid #edeffd;

  .terrace_check {
    padding-left: 20px;
    padding-top: 30px;

    >.terrace_tb_title {
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .terrace_header {
      padding-left: 8px;
      line-height: 14px;
      border-left: 2px solid #0981ff;
      margin-top: 19px;
      font-size: 14px;
      font-weight: 700;
      color: #333333;

      >i {
        cursor: pointer;
        margin-left: 14px;
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid #0981ff;
        //text-decoration: underline;
        color: #0981ff;
      }
    }

    .terrace_table {
      padding-right: 20px;
      margin-top: 20px;
    }
  }
}

.scale_box {
  margin-top: 19px;
  padding-right: 20px;
  padding-bottom: 20px;

  >.scale_table {
    margin-top: 20px;
    padding-left: 20px;
  }
}

.terrace_tag {
  float: left;
  margin-bottom: 2px;
  margin-left: 10px;
  padding: 3px 20px;
  max-width: 237px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  border: 1px solid #0981ff;
  border-radius: 20px;
  color: #0981ff;
  cursor: pointer;
}

.rule_box {
  width: 100%;
  margin-bottom: 30px;

  >h2 {
    font-size: 18px;
    font-weight: 900;
    color: #000;
    line-height: 20px;
    padding-left: 6px;
    border-left: 6px solid #0981ff;
    margin-bottom: 10px;
  }

  >p {
    font-size: 14px;
    line-height: 20px;
    color: #000;

    >ul {
      padding-left: 2em;

      >li {
        padding-left: 14px;
        position: relative;

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #000;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.time_dialog_box {
  >p:first-of-type {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #f76453;
  }

  .null_icon {
    color: #0981ff;
    font-size: 50px;
    cursor: pointer;
    display: inline-block;
  }

  .exist_icon {
    color: #0981ff;
    font-size: 23px;
    cursor: pointer;
    display: inline-block;
    //transform: translateY(3px);
    padding-left: 8px;
  }

  .exist_icon_del {
    color: #f76453;
    transform: translateY(0.1px);
  }
}

.store_list_box {
  width: 100%;

  >p:first-of-type {
    color: #f76453;
    margin-bottom: 20px;
  }
}

/deep/ .el-range-separator {
  width: 8% !important;
}

//去除数字框上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield;
}

.price_interval {
  display: flex;

  >.el-form-item:first-of-type {
    padding-right: 20px;
    position: relative;

    &:before {
      content: "~";
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

/deep/ .el-input-group__append {
  background: #0981ff;
}

/deep/ .el-input-group__append {
  color: #fff !important;
}

.time_box {
  display: flex;
  align-items: center;

  .time_end {
    max-width: 167.5px;
  }

  .time_start {
    max-width: 167.5px;
    margin-right: 20px;
    position: relative;

    &::after {
      content: "~";
      position: absolute;
      right: -14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.button_top {
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faultBtn {
  width: 500px;
  height: 42px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;

  .active {
    text-align: center;
    color: white;
    width: 50%;
    height: 40px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 40px;
    background: #0981ff;
    border-radius: 20px;
  }

  .none {
    text-align: center;
    color: #333;
    width: 50%;
    height: 40px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 40px;
    border-radius: 20px;
  }
}
.content-box{
  padding: 0 40px;
  .checkbox-box{
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
}
.red-description{
  font-size: 12px;
  color: #f76453;
}
.title-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.el-tabs--card>.el-tabs__header {
    background-color: #f5f7fa;
    border-bottom: 1px solid #e4e7ed;
    margin: 0;
}
/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    color: #409eff;
    background-color: #fff;
    border-right-color: #dcdfe6;
    border-left-color: #dcdfe6;
}
</style>
